import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmEnvironmentComponent } from './confirm-environment/confirm-environment.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { XpoButtonModule, XpoDialogModule } from '@xpo-ltl/ngx-ltl-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {MatSelectModule} from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';



@NgModule({
  declarations: [
    ConfirmEnvironmentComponent,
    NotAuthorizedComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    XpoButtonModule,
    XpoDialogModule
  ]
})
export class DialogModule { }

<div class="appMetadata-container" fxLayout="row" fxLayoutAlign="left">
  <div *ngIf="submitting" class="loader">
    <div class="loader-content">
      <mat-progress-spinner
        *ngIf="submitting"
        mode="indeterminate"
        [strokeWidth]="5"
        [diameter]="60"
        class="loader-spinner"
      >
      </mat-progress-spinner>
    </div>
  </div>
  <xpo-card *ngIf="!submitting">
    <xpo-card-header>
      <xpo-card-title class="appMetadata-title">Applications List</xpo-card-title>
    </xpo-card-header>
    <xpo-card-content>
      <div class="appMetadata-action">
        <button mat-flat-button color="primary" (click)="actionClicked('add')">
          <mat-icon>add</mat-icon>Add Application
        </button>
      </div>
      <xpo-ag-grid [autoSizeColumns]="true" [sizeColumnsToFit]="true" class="appMetadata-grid">
        <input
          type="text"
          class="filterField input-grid"
          placeholder="Search Applications"
          (keyup)="quickSearch()"
          [(ngModel)]="searchValue"
        />

        <ag-grid-angular
          #agGrid
          style="height: calc(100vh - 340px);"
          [frameworkComponents]="frameworkComponents"
          [headerHeight]="40"
          [rowHeight]="40"
          [rowData]="rowData"
          [columnDefs]="columnDefs"
          [enableCellTextSelection]="true"
        >
        </ag-grid-angular>
      </xpo-ag-grid>
    </xpo-card-content>
  </xpo-card>
</div>

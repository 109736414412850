import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Application, Browser } from '@xpo-ltl/sdk-appmetadata';
import { ActionCd, AuditInfo } from '@xpo-ltl/sdk-common';
import { ActionEnum } from '../../shared/enums/actions.enum';

@Component({
  selector: 'appmetadata-dialog',
  templateUrl: './main-dialog.component.html',
  styleUrls: ['./main-dialog.component.scss'],
})
export class MainDialogComponent {
  appName: string = '';
  minVersion: string = '';
  groupName: string = '';
  url: string = '';
  title: string = '';
  mode: string = '';
  isUpdateDialog: boolean = false;
  selected: number[] = [];
  rowData: Application[] | undefined;
  browserList: Browser[] = [
    {
      browserInstId: 67,
      browserName: 'Edge',
      versionNbr: '1.0.1',
      listActionCd: ActionCd.ADD,
      auditInfo: new AuditInfo
    },
    {
      browserInstId: 0,
      browserName: 'internet e',
      versionNbr: '73.0.*',
      listActionCd: ActionCd.ADD,
      auditInfo: new AuditInfo
    },
    {
      browserInstId: 65,
      browserName: 'safari',
      versionNbr: '73.0.*',
      listActionCd: ActionCd.ADD,
      auditInfo: new AuditInfo
    },
    {
      browserInstId: 2,
      browserName: 'opera',
      versionNbr: '1.0.0',
      listActionCd: ActionCd.ADD,
      auditInfo: new AuditInfo
    },
    {
      browserInstId: 3,
      browserName: 'firefox',
      versionNbr: '1.0.0',
      listActionCd: ActionCd.ADD,
      auditInfo: new AuditInfo
    },
    {
      browserInstId: 66,
      browserName: 'Chrome Canary',
      versionNbr: '73.0.*',
      listActionCd: ActionCd.ADD,
      auditInfo: new AuditInfo
    },
    {
      browserInstId: 1,
      browserName: 'Google Chrome',
      versionNbr: '73.0',
      listActionCd: ActionCd.ADD,
      auditInfo: new AuditInfo
    },
  ]
  applInstId: number;

  constructor(public dialogRef: MatDialogRef<MainDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.title = data.title;
    this.applInstId = data.params.applInstId;

    switch (data.mode) {
      case ActionEnum.ADD:
        this.mode = data.mode;
        this.rowData = data.params.rowData;
        break;

      case ActionEnum.UPDATE:
        this.mode = data.mode;
        this.appName = data.params.appName;
        this.minVersion = data.params.minVersion;
        this.groupName = data.params.groupName;
        data.params.browser.forEach((x: Browser )  => 
          this.selected.push(x.browserInstId));
        this.url = data.params.url,
        this.isUpdateDialog = true;
        break;
    }
  }

  save(): void {
    let selectedBrowserList: Browser[] = [];
    for(let i = 0;i < this.selected.length;i++)
    {
      const rowIndex = this.browserList.findIndex((element) => element.browserInstId === this.selected[i]);
      selectedBrowserList.push(this.browserList[rowIndex]);
    }
    const saveRqst = {
      appName: this.appName,
      minVersion: this.minVersion,
      url: this.url,
      groupName: this.groupName,
      applInstId: this.applInstId,
      mode: this.mode,
      browser: selectedBrowserList,
    };
    this.dialogRef.close(saveRqst);
  }
}

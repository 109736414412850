<ng-container>
    <button
      tabindex="-1"
      matTooltip="Update Application"
      matTooltipPosition="above"
      (click)="callAction($event)"
      mat-icon-button
    >
    <mat-icon>edit</mat-icon>
    </button>
</ng-container>
  
export enum SymRegions {
  TCTS4 = 'TCTS4',
  TCTS5 = 'TCTS5',
  TCTSA = 'TCTSA',
  TCTSC = 'TCTSC',
  TCTSG = 'TCTSG',
  TCTSI = 'TCTSI',
  TCTSJ = 'TCTSJ',
  TCTSL = 'TCTSL',
  TCTSN = 'TCTSN',
  TCTSR = 'TCTSR',
  TCTSS = 'TCTSS',
}

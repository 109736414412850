<xpo-shell [drawerClosedByDefault]="true">
  <xpo-header-app-name>Application</xpo-header-app-name>
  <xpo-header-sub-app-name>MetaData</xpo-header-sub-app-name>
  <xpo-header-navigation>
    <button *ngFor="let route of routes" [routerLink]="route.path" routerLinkActive="xpo-selected">
      {{ route.label }}
    </button>
  </xpo-header-navigation>
  <xpo-header-actions>
    <xpo-filter-bar
      [style.width]="'auto'"
      [filters]="filters"
      (criteriaChange)="onGlobalFilterChange($event)"
    ></xpo-filter-bar>
    <xpo-auth-user-profile></xpo-auth-user-profile>
  </xpo-header-actions>
  <div class="content">
    <router-outlet></router-outlet>
    <xpo-footer xpoFooterSticky [version]="build"></xpo-footer>
  </div>
</xpo-shell>

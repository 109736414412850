<div class="ShellHeader">
  <nav mat-tab-nav-bar class="ShellHeader__mat-tab-nav-bar">
    <a
      mat-tab-link
      class="ShellHeader_options"
      *ngFor="let link of navLinks"
      [routerLink]="link.path"
      #rla="routerLinkActive"
      routerLinkActive
      [attr.data-test]="'appNavLink_' + link.label"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      {{ link.label }}
    </a>
  </nav>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DevRegions } from '../../shared/enums/dev-regions.enum';
import { SymRegions } from '../../shared/enums/sym-regions.enum';
import { SysRegions } from '../../shared/enums/sys-regions.enum';
import { RegionGroup } from './region-group.model';

@Component({
  selector: 'confirm-environment',
  templateUrl: './confirm-environment.component.html',
  styleUrls: ['./confirm-environment.component.scss'],
})
export class ConfirmEnvironmentComponent implements OnInit {
  isProd: boolean = false;
  inputValue: string = "";
  regionGroups: RegionGroup[];
  enableConfirm: boolean = false;

  selectFormControl = new FormControl('', Validators.required);

  constructor(
    public dialogRef: MatDialogRef<ConfirmEnvironmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data : any
  ) {
    this.regionGroups = this.buildRegionGroups();
  }

  ngOnInit() {
    this.isProd = this.data.prod;
  }

  checkProdInput(event: KeyboardEvent): void {
    this.enableConfirm = this.inputValue.toUpperCase() === 'PROD';
    if (this.enableConfirm) {
      this.selectFormControl.setValue('WAVE');
      if (event.key === 'Enter') {
        this.dialogRef.close(this.selectFormControl.value);
      }
    }
  }

  disableConfirm(): boolean {
    if (this.isProd) {
      return !this.enableConfirm;
    } else {
      return this.selectFormControl.hasError('required');
    }
  }

  onConfirm() {
    this.dialogRef.close(this.selectFormControl.value);
  }

  private buildRegionGroups(): RegionGroup[] {
     const devRegions: RegionGroup = {
       name: '-- DEV --',
       regions: this.devRegions,
     };
     const sysRegions: RegionGroup = {
       name: '-- SYSTEM --',
       regions: this.sysRegions,
     };
     const preProdRegions: RegionGroup = {
       name: '-- PRE-PROD --',
       regions: [SymRegions.TCTSS],
     };
     return [devRegions, sysRegions, preProdRegions];
  }

  get devRegions(): string[] {
    return Object.keys(DevRegions);
  }

  get symRegions(): string[] {
    return Object.keys(SymRegions);
  }

  get sysRegions(): string[] {
    return Object.keys(SysRegions);
  }
}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'grid-actions',
  templateUrl: './grid-actions.component.html',
})
export class GridActionsComponent implements ICellRendererAngularComp {
  params: any;

  refresh(): boolean {
    return false;
  }

  agInit(params : any) {
    this.params = params;
  }

  constructor(public dialog: MatDialog) {}

  callAction($event : any): void {
    if (this.params.onClick instanceof Function) {
        const params = {
          rowData: this.params.node.data
        }
        this.params.onClick(params);
      }
  }
}

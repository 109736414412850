import { Component, OnDestroy, OnInit } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import {
  Application,
  AppMetadataApiService,
  CreateAppMetadataRqst,
  UpdateAppMetadataRqst,
} from '@xpo-ltl/sdk-appmetadata';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarNotifications } from '../shared/enums/snackbar-status.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { ColDef, GridApi } from 'ag-grid-community';
import { GridActionsComponent } from '../welcome/grid-actions/grid-actions.component';
import { MainDialogComponent } from '../welcome/main-dialog/main-dialog.component';
import { ActionEnum } from '../shared/enums/actions.enum';
import { AuditInfo } from '@xpo-ltl/sdk-common';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})
export class ApplicationsComponent implements OnInit, OnDestroy {
  rowData: Application[];
  selectionMode: 'row' | 'cell' = 'row';
  selectedRow: any = null;
  searchValue!: string;
  submitting = false;
  dialogTitles = {
    add: 'Add Application',
    update: 'Update Application',
  };

  private gridApi: GridApi | undefined;
  frameworkComponents: any;

  columnDefs: ColDef[] = [
    {
      headerName: 'Actions',
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      cellRenderer: 'gridActionsRenderer',
      cellRendererParams: {
        onClick: this.updateApplication.bind(this),
      },
    },
    {
      headerName: 'App Name',
      field: 'applName',
      minWidth: 150,
      maxWidth: 300,
      resizable: true,
      cellStyle: { 'border-left': 'none', 'text-align': 'left' },
      sortable: true,
    },
    {
      headerName: 'Min. Version',
      minWidth: 120,
      maxWidth: 130,
      field: 'minimumVersionNbr',
      resizable: true,
      sortable: true,
    },
    { headerName: 'Link', field: 'url', resizable: true, sortable: true },
    { headerName: 'Group Name', field: 'groupName', maxWidth: 170, resizable: true, sortable: true },
    {
      headerName: 'Icon',
      field: 'icon',
      maxWidth: 100,
      resizable: true,
      cellRenderer: (params: any) =>
        params.data.icon ? `<img style="height: 20px; width: 20px" src={${this.getImagePath(params)}} />` : ``,
    },
    {
      headerName: 'Supported Browsers',
      resizable: true,
      sortable: true,
      flex: 1,
      valueGetter: this.getBrowser,
    },
  ];

  constructor(
    private _sanitizer: DomSanitizer,
    private appMetaDataService: AppMetadataApiService,
    private xpoSnackBar: XpoSnackBar,
    private dialog: MatDialog
  ) {
    this.frameworkComponents = {
      gridActionsRenderer: GridActionsComponent,
    };

    this.rowData = new Array<Application>();
  }

  ngOnInit(): void {
    this.fetchData();
  }

  getBrowser(params: any): string {
    let browsers: string = '';
    for (let i = 0; i < params.data.browser.length; i++) {
      if (i == 0) {
        browsers = browsers.concat(params.data.browser[i].browserName);
      } else {
        browsers = browsers.concat(', ' + params.data.browser[i].browserName);
      }
    }
    return browsers;
  }

  fetchData(): void {
    this.submitting = true;
    this.appMetaDataService.listApplications().subscribe(
      (response) => {
        this.rowData = response.applications;
        this.submitting = false;
      },
      (error) => {
        this.submitting = false;
        console.log(error);
      }
    );
  }

  getImagePath(params: any): any {
    const imagePath = this._sanitizer.bypassSecurityTrustResourceUrl(
      'data:image/jpg;base64,' + params.data.icon.base64String
    );
    return imagePath;
  }

  updateApplication(e: any) {
    this.selectedRow = e.rowData;
    this.actionClicked('update');
  }

  clearSelection(): void {
    this.gridApi.clearFocusedCell();
    this.selectedRow = null;
  }

  quickSearch(): void {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  openDialog(title: string, mode: string, data?: any): void {
    const dialogRef = this.dialog.open(MainDialogComponent, {
      width: '400px',
      data: {
        mode: mode,
        title: title,
        params: data,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.clearSelection();
        return;
      }

      const application: Application = {
        applName: result.appName,
        url: result.url,
        applInstId: result.applInstId,
        minimumVersionNbr: result.minVersion,
        icon: '',
        groupName: result.groupName,
        applicationDescription: '',
        auditInfo: new AuditInfo(),
        browser: result.browser,
      };

      switch (result.mode) {
        case ActionEnum.ADD:
          const requestAdd: CreateAppMetadataRqst = {
            application,
          };
          this.appMetaDataService.createAppMetadata(requestAdd).subscribe(
            () => {
              this.appMetaDataService.listApplications().subscribe(
                (response) => {
                  for (let i = 0; i < response.applications.length; i++) {
                    const rowIndex = this.rowData.findIndex(
                      (element) => element.applInstId === response.applications[i].applInstId
                    );
                    if (rowIndex == -1) {
                      this.gridApi.updateRowData({ add: [response.applications[i]] });
                    }
                  }
                },
                (error) => {
                  console.log(error);
                  this.xpoSnackBar.error(SnackbarNotifications.ADD_APPLICATION_FAILED);
                }
              );
            },
            (error) => {
              console.log(error);
              this.xpoSnackBar.error(SnackbarNotifications.ADD_APPLICATION_FAILED);
            },
            () => {
              this.xpoSnackBar.success(SnackbarNotifications.ADD_APPLICATION_SUCCESS);
              this.clearSelection();
            }
          );
          break;

        case ActionEnum.UPDATE:
          const requestUpdate: UpdateAppMetadataRqst = {
            application,
          };
          this.appMetaDataService.updateAppMetadata(requestUpdate).subscribe(
            () => {
              this.appMetaDataService.listApplications().subscribe(
                (response) => {
                  const newRowIndex = response.applications.findIndex(
                    (element) => element.applInstId === application.applInstId
                  );
                  const rowIndex = this.rowData.findIndex((element) => element.applInstId === application.applInstId);
                  this.rowData[rowIndex].applName = response.applications[newRowIndex].applName;
                  this.rowData[rowIndex].minimumVersionNbr = response.applications[newRowIndex].minimumVersionNbr;
                  this.rowData[rowIndex].browser = response.applications[newRowIndex].browser;
                  this.rowData[rowIndex].url = response.applications[newRowIndex].url;
                  this.rowData[rowIndex].groupName = response.applications[newRowIndex].groupName;
                  this.gridApi.redrawRows();
                },
                (error) => {
                  console.log(error);
                  this.xpoSnackBar.error(SnackbarNotifications.UPDATE_APPLICATION_FAILED);
                }
              );
            },
            (error) => {
              console.log(error);
              this.xpoSnackBar.error(SnackbarNotifications.UPDATE_APPLICATION_FAILED);
            },
            () => {
              this.xpoSnackBar.success(SnackbarNotifications.UPDATE_APPLICATION_SUCCESS);
              this.clearSelection();
            }
          );
          break;
      }
    });
  }

  actionClicked(action: any): void {
    switch (action) {
      case ActionEnum.ADD:
        const addParams: any = {
          rowData: this.rowData,
        };
        this.openDialog(this.dialogTitles.add, action, addParams);
        break;

      case ActionEnum.UPDATE:
        const updateParams: any = {
          appName: this.selectedRow.applName,
          minVersion: this.selectedRow.minimumVersionNbr,
          url: this.selectedRow.url,
          groupName: this.selectedRow.groupName,
          browser: this.selectedRow.browser,
          applInstId: this.selectedRow.applInstId,
        };
        this.openDialog(this.dialogTitles.update, action, updateParams);
        break;
    }
  }

  ngOnDestroy(): void {}
}

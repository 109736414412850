import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { ApplicationsComponent } from './applications/applications.component';
import { BrowsersComponent } from './browsers/browsers.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { WelcomeComponent } from './welcome/welcome.component';
import {RegionInfoGuard} from './guards/region-info.guard';

export const RouterDefinitions: Routes = [
  {
    path: AppRoutes.CONFIRM,
    component: ConfirmComponent,
    canActivate: [XpoAuthenticationGuard]
  },
  {
    path: AppRoutes.APPS,
    component: WelcomeComponent,
    canActivate: [XpoAuthenticationGuard, RegionInfoGuard],
    children: [
      {path: AppRoutes.APPLICATION, component: ApplicationsComponent},
      {path: AppRoutes.BROWSER, component: BrowsersComponent},
    ],
  },
  {
    path: '**',
    redirectTo: AppRoutes.CONFIRM,
    pathMatch: 'full',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(RouterDefinitions, { enableTracing: false , paramsInheritanceStrategy: 'always', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule, CommonModule],
})
export class AppRoutingModule {}

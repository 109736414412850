import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfigManagerService} from '@xpo-ltl/config-manager';
import {Router} from '@angular/router';
import {ConfirmEnvironmentComponent} from '../dialog/confirm-environment/confirm-environment.component';
import {ConfigManagerProperties} from '../shared/enums/config-manager-properties.enum';
import {AppRoutes} from '../shared/enums/app-routes.enum';
import {XpoLtlAuthConfigLoaderService, XpoLtlAuthenticationService} from '@xpo-ltl/ngx-auth';
import {GlobalFilterService} from '../shared/services/filter/global-filter.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements OnInit {
  isProd: boolean;

  constructor(
    private authConfigService: XpoLtlAuthConfigLoaderService,
    private authService: XpoLtlAuthenticationService,
    private globalFilterService: GlobalFilterService,
    private dialog: MatDialog,
    private configManagerService: ConfigManagerService,
    private router: Router
  ) {
    this.isProd = configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
  }

  ngOnInit(): void {
    const dialogRef = this.dialog.open(ConfirmEnvironmentComponent, {
      width: this.isProd ? '600px' : '400px',
      data: {
        prod: this.isProd,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((region) => {
      if (region) {
        this.authService.logout();
        console.log(region);
        this.authService.initAuthSetup$(region).subscribe((info) => {
          console.log(info);
          this.authConfigService.apiUrl = info.apiUrl;
          this.configManagerService.setSetting('apiUrl', info.apiUrl);
          // This call triggers filter changed event.
          this.globalFilterService.regionFilter = region;
          this.router.navigate([AppRoutes.APPS, AppRoutes.APPLICATION]);
        });
      }
    });
  }
}

export enum SnackbarStatus {
    error = 'error',
    success = 'success',
    warn = 'warn',
    warning = 'warn',
  }
  
  export enum SnackbarNotifications {
    ADD_APPLICATION_SUCCESS = 'Application has been successfully added!',
    ADD_APPLICATION_FAILED = 'Application could not be added.',
    UPDATE_APPLICATION_SUCCESS = 'Application has been successfully updated!',
    UPDATE_APPLICATION_FAILED = 'Application could not be updated.',
  }
  
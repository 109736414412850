import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { XpoLtlAuthConfigLoaderService } from '@xpo-ltl/ngx-auth';
import {AppRoutes} from '../shared/enums/app-routes.enum';

@Injectable({
  providedIn: 'root',
})
export class RegionInfoGuard implements CanActivate, CanLoad {
  constructor(private authConfigService: XpoLtlAuthConfigLoaderService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (
      !this.authConfigService.apiUrl ||
      !this.authConfigService.consumerKey ||
      !this.authConfigService.consumerSecret
    ) {
      this.router.navigate([AppRoutes.CONFIRM]);
      return false;
    }
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    if (
      !this.authConfigService.apiUrl ||
      !this.authConfigService.consumerKey ||
      !this.authConfigService.consumerSecret
    ) {
      this.router.navigate([AppRoutes.CONFIRM]);
      return false;
    }
    return true;
  }
}

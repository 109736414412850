import { Component, OnInit } from '@angular/core';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core';
import { AppMetadataApiService } from '@xpo-ltl/sdk-appmetadata';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoFilter } from '@xpo-ltl/ngx-ltl-board';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { ConfigManagerProperties } from '../shared/enums/config-manager-properties.enum';
import { Router } from '@angular/router';
import { GlobalFilterService } from '../shared/services/filter/global-filter.service';
import { take } from 'rxjs/operators';
import { AppRoutes } from '../shared/enums/app-routes.enum';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  reload = true;
  rowSelection: string;
  filters: XpoFilter[];
  submitting = false;
  selectedRegion: string;
  routes: XpoShellRoute[];
  build: string;

  constructor(
    private router: Router,
    private appMetaDataService: AppMetadataApiService,
    private configManagerService: ConfigManagerService,
    private authConfigService: XpoLtlAuthConfigLoaderService,
    private globalFilterService: GlobalFilterService,
    private authService: XpoLtlAuthenticationService
  ) {
    this.routes = [
      {
        label: 'APPLICATION',
        path: AppRoutes.APPLICATION,
      },
      {
        label: 'BROWSER',
        path: AppRoutes.BROWSER,
      },
    ];
    this.build = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.rowSelection = 'single';
    this.filters = globalFilterService.setupGlobalFilters();
    this.selectedRegion = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
  }

  ngOnInit(): void {
    // this.submitting = true;
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.logout();
    this.authService.initAuthSetup$(region).subscribe((info) => {
      console.log('RegionInfo:', info);
    });
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  onGlobalFilterChange($event: any): void {
    // this.submitting = true;
    if (!this.selectedRegion) {
      this.selectedRegion = $event.region;
    } else if ($event.region && $event.region !== this.selectedRegion) {
      // this.rowData = new Array<Application>();
      this.authService.logout();
      this.authService
        .initAuthSetup$($event.region)
        .pipe(take(1))
        .subscribe((info) => {
          this.authConfigService.apiUrl = info.apiUrl;
          this.configManagerService.setSetting('apiUrl', info.apiUrl);
          this.configManagerService.setSetting('region', $event.region);
          this.globalFilterService.publishRegionChange($event.region);
          // this.fetchData();
          this.submitting = false;
        });
      this.selectedRegion = $event.region;
    }
  }
}

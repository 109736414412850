<div class="appMetadata-dialog">
    <h1 mat-dialog-title>
      {{ title }}
      <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>

    <form #MainDialogForm="ngForm">
      <div mat-dialog-content>
        <mat-form-field floatLabel="always">
          <mat-label>Application Name</mat-label>
          <input
            #componentCode="ngModel"
            [(ngModel)]="appName"
            matInput
            name="appName"
            placeholder="Application Name"
            required
          />
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <mat-label>App URL</mat-label>
          <input
            #desc="ngModel"
            matInput
            [(ngModel)]="url"
            name="url"
            pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
            noSpaceOnly
            placeholder="App URL"
            required
          />
          <mat-hint *ngIf="desc.errors?.pattern"> Url must be in the format https://appName.com</mat-hint>
        </mat-form-field>

        <mat-form-field floatLabel="always">
            <mat-label>Minimum Version</mat-label>
            <input
              matInput
              [(ngModel)]="minVersion"
              name="minVersion"
              pattern="[A-Za-z0-9\s-_@#':\,\.\[\]\(\)]{0,100}"
              noSpaceOnly
              placeholder="Minimum Version"
              required
            />
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <mat-label>Group Name</mat-label>
          <input
            #componentCode="ngModel"
            [(ngModel)]="groupName"
            matInput
            name="groupName"
            placeholder="Group Name"
            required
          />
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <mat-label>Select Browser</mat-label>
          <mat-select class="mat-select-form" placeholder="Select Browser" [(value)]="selected" required multiple>
              <mat-option *ngFor="let browser of browserList" [value]="browser.browserInstId">
                  {{ browser.browserName }}
              </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div mat-dialog-actions>
        <button (click)="save()" [disabled]="!MainDialogForm.valid" mat-flat-button>Save</button>
        <button (click)="dialogRef.close()" color="primary" mat-button>Cancel</button>
      </div>
    </form>
  </div>

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';

@Component({
  selector: 'app-shell-header',
  templateUrl: './shell-header.component.html',
  styleUrls: ['./shell-header.component.scss']
})
export class ShellHeaderComponent implements OnInit {

  navLinks = [
    {
      label: 'Applications',
      path: AppRoutes.APPLICATION
    },
    {
      label: 'Browsers',
      path: AppRoutes.BROWSER
    },
  ];
  constructor(private changeDetectionRef: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {
    // this.router.navigate([AppRoutes.APPS, AppRoutes.APPLICATION]);
    this.subscribeToNavigationEndChanges();
  }

  private subscribeToNavigationEndChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => this.changeDetectionRef.markForCheck());
  }

}

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import {
  XpoButtonModule,
  XpoCardModule,
  XpoDialogModule,
  XpoApplicationSwitcherModule,
  XpoIconModule,
  XpoShellModule,
  XpoPageNotFoundRoutingModule,
  XpoFooterModule,
} from '@xpo-ltl/ngx-ltl-core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { XpoAuthConfig, XpoAuthModule, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import { AgGridModule } from 'ag-grid-angular';
import {AppMetadataApiService, SdkAppMetadataModule} from '@xpo-ltl/sdk-appmetadata'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { DialogModule } from './dialog/dialog.module';
import { environment } from '../environments/environment';
import { WelcomeComponent } from './welcome/welcome.component';
import { MainDialogComponent } from './welcome/main-dialog/main-dialog.component';
import { XpoFiltersModule, XpoFiltersService } from '@xpo-ltl/ngx-ltl-board';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import {FlexModule} from '@angular/flex-layout';
import { GridActionsComponent } from './welcome/grid-actions/grid-actions.component';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core';
import { ApplicationsComponent } from './applications/applications.component';
import { BrowsersComponent } from './browsers/browsers.component';
import { ShellHeaderComponent } from './welcome/components/shell-header/shell-header.component';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: environment.authAppRootUri,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmComponent,
    WelcomeComponent,
    MainDialogComponent,
    GridActionsComponent,
    ApplicationsComponent,
    BrowsersComponent,
    ShellHeaderComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AgGridModule,
    DialogModule,
    BrowserModule,
    ConfigManagerModule,
    BrowserAnimationsModule,
    FormsModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    HttpClientModule,
    XpoShellModule,
    XpoButtonModule,
    XpoCardModule,
    XpoAgGridModule,
    XpoDialogModule,
    XpoAuthModule,
    XpoApplicationSwitcherModule,
    XpoIconModule,
    XpoSnackBarModule,
    XpoFiltersModule,
    SdkAppMetadataModule,
    FlexModule,
    XpoFooterModule,
    // FOR SSO
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    AppMetadataApiService,
    XpoFiltersService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
